<template>
  <div>
    <!-- modal -->
    <b-modal
      id="modal-primary"
      :ok-only="false"
      ok-title="บันทึกข้อมูล"
      cancel-title="ยกเลิก"
      modal-class="modal-primary"
      :title="_title"
      :ok-disabled="loading"
      :cancel-disabled="loading"
      size="xl"
      @ok="clickSave"
      @cancel="clickCancel"
    >
      <b-overlay :show="loading">
        <b-card-text>
          <validation-observer ref="formp">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="หัวข้อ" label-for="a-title">
                    <validation-provider #default="{ errors }" name="title" rules="required">
                      <b-form-input
                        id="a-title"
                        v-model="form.title"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="กรอก หัวข้อ"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ข้อความ" label-for="a-body">
                    <validation-provider #default="{ errors }" name="body" rules="required">
                      <b-form-textarea
                        id="a-body"
                        v-model="form.body"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        placeholder="กรอก ข้อความ"
                        rows="4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ตั้งเวลา (ถ้าไม่ตั้งจะเป็นการส่ง ณ เวลาปัจจุบัน)" label-for="time-setting">
                    <flat-pickr
                      v-model="form.dateSetting"
                      :config="configDate"
                      class="form-control text-center"
                      placeholder="วัน/เดือน/ปี (ค.ศ.) ชั่วโมง:นาที:วินาที"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { Thai } from 'flatpickr/dist/l10n/th'

const DATE_SETTING_DEFAULT = new Date().setMinutes(new Date().getMinutes() + 10)

export default {
  components: {
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      numeric,
      double,
      email,
      form: {
        id: '',
        title: '',
        body: '',
        dateSetting: null,
      },
      configDate: {
        dateFormat: 'Z',
        altFormat: 'j/F/Y H:i:S',
        altInput: true,
        locale: Thai,
        minDate: DATE_SETTING_DEFAULT,
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
      },
      loading: false,
    }
  },
  computed: {
    _title() {
      return `${this.form.id ? 'แก้ไข' : 'สร้าง'} แจ้งเตือน`
    },
  },
  methods: {
    show(data = '') {
      this.form = {
        id: '',
        title: '',
        body: '',
        dateSetting: null,
      }
      this.configDate.minDate = DATE_SETTING_DEFAULT
      if (data) this.form = data
      this.$bvModal.show('modal-primary')
    },
    async clickSave(data) {
      data.preventDefault()
      const validate = await this.$refs.formp.validate()
      if (!validate) return ''

      const newForm = {
        ...this.form,
        dateSetting: this.form?.dateSetting ? this.$date(this.form.dateSetting).format('YYYY-MM-DD HH:mm:ss') : null,
      }
      // console.log('newForm', newForm)

      // return

      this.loading = true
      const resp = await this.api.post('/api/notification-data/updateOrCreate', newForm)
      this.loading = false
      this.$bvModal.hide('modal-primary')
      this.$emit('reload', '')
    },
    clickCancel() {},
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
